import { App, ref } from "vue";
import { log } from "@/utils/log";

export const isConnected: any = ref(false);
const isConnection: any = ref(false);
const ws: any = ref(null);

function send(request: any) {
    if (!ws.value) {
        throw new Error('Подключение к NCALayer не установлено.');
    }
    ws.value.send(JSON.stringify(request));
}

function setHandlers(resolve: any, reject: any) {
    ws.value.onerror = () => {
        reject(new Error('Ошибка взаимодействия с NCALayer. В том случае, если на вашем компьютере не установлен NCALayer, пожалуйста установите его c портала НУЦ РК (https://pki.gov.kz/ncalayer/). Если же NCALayer установлен, но портал выдает ошибку, свяжитесь, пожалуйста, с нашей технической поддержкой.'));
    };

    ws.value.onclose = () => {
        reject(new Error('NCALayer закрыл соединение.'));
    };

    ws.value.onmessage = (msg: any) => {
        const response = JSON.parse(msg.data);

        if (!response.status) {
            reject(new Error(`${response.code}: ${response.message}`));
            return;
        }
        if(Object.keys(response.body).length) {
            resolve(response.body.result[0]);
        }else{
            reject(false)
        }
    };
}

export async function signXml(xml: any, tbsElementXPath = '', signatureParentElementXPath = '') {
    const request = {
        module: 'kz.gov.pki.knca.commonUtils',
        method: 'signXml',
        args: [
            'PKCS12',
            'SIGNATURE',
            xml,
            tbsElementXPath,
            signatureParentElementXPath,
        ],
    };

    send(request);

    return new Promise((resolve, reject) => {
        setHandlers(resolve, reject);
    });
}

export async function signBasic(storageNames: any, signatureType: any, dataToSign: any, decode: any, encapsulate: any, digested: any, keyOids: any, localeRadio: any)
{
    let singingParams;
    if (signatureType === 'xml') {
        singingParams = {
            decode: false,
            encapsulate: false,
            digested: false,
            tsaProfile: null
        };
    } else {
        singingParams = {
            decode: decode,
            encapsulate: encapsulate,
            digested: digested,
            tsaProfile: true
        };
    }

    let signInfo = {
        "module": "kz.gov.pki.knca.basics",
        "method": "sign",
        "args": {
            "allowedStorages": storageNames,
            "format": signatureType,
            "data": dataToSign,
            "signingParams": singingParams,
            "signerParams": {
                "extKeyUsageOids": keyOids
            },
            "locale": localeRadio
        }
    }

    send(signInfo);

    return new Promise((resolve, reject) => {
        setHandlers(resolve, reject);
    });
};

function connect() {
    return new Promise((resolve, reject) => {
        // readyState
        // 0 CONNECTING Сокет создан. Соединение еще не открыто.
        // 1 OPEN Соединение открыто и готово к общению.
        // 2 CLOSING Соединение находится в процессе закрытия.
        // 3 CLOSED Соединение закрыто или не может быть открыто.

        if (ws.value !== null && ws.value.readyState === 1) resolve(ws.value);

        if (ws.value === null || [2, 3].includes(ws.value.readyState)) {
            try {
                ws.value = new WebSocket('wss://127.0.0.1:13579');
                isConnection.value = true;
                ws.value.onopen = function () {
                    isConnected.value = true;
                    log('NCALayer:onopen', 'Подключено');
                    resolve(ws.value);
                };
                ws.value.onerror = function (err: any) {
                    isConnection.value = false;
                    isConnected.value = false;
                    ws.value = null;
                    // log('NCALayer:onerror', 'Ошибка подключения');
                    reject(err);
                };
                ws.value.onclose = function () {
                    isConnection.value = false;
                    isConnected.value = false;
                    ws.value = null;
                    // log('NCALayer:onclose', 'Закрытие соединения');
                    reject();
                };
            } catch (e) {
                reject();
                console.log('NCALayer Error', e);
            }
        }
    });
}

export const NCALayer = {
    install(Vue: App) {
        // setInterval(() => connect(), 2000);
    },
};
